import NatureTileProvider, {NatureTile} from '@/base/tile/providers/NatureTileProvider';
import TileObject from '@/base/tile/TileObject';
import NatureSupportTileProvider, {NatureSupportTile} from '@/base/tile/providers/NatureSupportTileProvider';
import RubyTownProvider, {RubyTownTile} from '@/base/tile/providers/RubyTownProvider';
import BridgeTileProvider, {BridgeTile} from '@/base/tile/providers/BridgeTileProvider';

export default class CommonTileProvider {
  public static getNatureTile(tile: NatureTile): TileObject<NatureTile> {
    return CommonTileProvider.provideNatureProvider().getTile(tile);
  }

  public static getNatureSupportTile(tile: NatureSupportTile): TileObject<NatureSupportTile> {
    return CommonTileProvider.provideNatureSupportProvider().getTile(tile);
  }

  public static getRubyTownTile(tile: RubyTownTile): TileObject<RubyTownTile> {
    return CommonTileProvider.provideRubyTownProvider().getTile(tile);
  }

  public static getBridgeTile(tile: BridgeTile): TileObject<BridgeTile> {
    return CommonTileProvider.provideBridgeProvider().getTile(tile);
  }

  public static provideNatureProvider(): NatureTileProvider<NatureTile> {
    return new NatureTileProvider<NatureTile>();
  }

  public static provideNatureSupportProvider(): NatureSupportTileProvider<NatureSupportTile> {
    return new NatureSupportTileProvider<NatureSupportTile>();
  }

  public static provideRubyTownProvider(): RubyTownProvider<RubyTownTile> {
    return new RubyTownProvider<RubyTownTile>();
  }

  public static provideBridgeProvider(): BridgeTileProvider<BridgeTile> {
    return new BridgeTileProvider<BridgeTile>();
  }
}