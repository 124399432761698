import TileProvider from '@/base/tile/internal/TileProvider';
import {ProviderType} from '@/base/tile/providers/helpers/ProviderType';

export default class RubyTownProvider<T> extends TileProvider<RubyTownTile> {
  public provideBaseDirectory(): string {
    return "@/base/tile/assets/rubytown"
  }

  public provideTileProviderType(): ProviderType {
    return ProviderType.RUBYTOWN;
  }
}

export enum RubyTownTile {
  RUBYTOWN_0 = 256,
  RUBYTOWN_1 = 1,
  RUBYTOWN_2 = 2,
  RUBYTOWN_3 = 3,
  RUBYTOWN_4 = 4,
  RUBYTOWN_5 = 5,
  RUBYTOWN_6 = 6,
  RUBYTOWN_7 = 7,
  RUBYTOWN_8 = 8,
  RUBYTOWN_9 = 9,
  RUBYTOWN_10 = 10,
  RUBYTOWN_11 = 11,
  RUBYTOWN_12 = 12,
  RUBYTOWN_13 = 13,
  RUBYTOWN_14 = 14,
  RUBYTOWN_15 = 15,
  RUBYTOWN_16 = 16,
  RUBYTOWN_17 = 17,
  RUBYTOWN_18 = 18,
  RUBYTOWN_19 = 19,
  RUBYTOWN_20 = 20,
  RUBYTOWN_21 = 21,
  RUBYTOWN_22 = 22,
  RUBYTOWN_23 = 23,
  RUBYTOWN_24 = 24,
  RUBYTOWN_25 = 25,
  RUBYTOWN_26 = 26,
  RUBYTOWN_27 = 27,
  RUBYTOWN_28 = 28,
  RUBYTOWN_29 = 29,
  RUBYTOWN_30 = 30,
  RUBYTOWN_31 = 31,
  RUBYTOWN_32 = 32,
  RUBYTOWN_33 = 33,
  RUBYTOWN_34 = 34,
  RUBYTOWN_35 = 35,
  RUBYTOWN_36 = 36,
  RUBYTOWN_37 = 37,
  RUBYTOWN_38 = 38,
  RUBYTOWN_39 = 39,
  RUBYTOWN_40 = 40,
  RUBYTOWN_41 = 41,
  RUBYTOWN_42 = 42,
  RUBYTOWN_43 = 43,
  RUBYTOWN_44 = 44,
  RUBYTOWN_45 = 45,
  RUBYTOWN_46 = 46,
  RUBYTOWN_47 = 47,
  RUBYTOWN_48 = 48,
  RUBYTOWN_49 = 49,
  RUBYTOWN_50 = 50,
  RUBYTOWN_51 = 51,
  RUBYTOWN_52 = 52,
  RUBYTOWN_53 = 53,
  RUBYTOWN_54 = 54,
  RUBYTOWN_55 = 55,
  RUBYTOWN_56 = 56,
  RUBYTOWN_57 = 57,
  RUBYTOWN_58 = 58,
  RUBYTOWN_59 = 59,
  RUBYTOWN_60 = 60,
  RUBYTOWN_61 = 61,
  RUBYTOWN_62 = 62,
  RUBYTOWN_63 = 63,
  RUBYTOWN_64 = 64,
  RUBYTOWN_65 = 65,
  RUBYTOWN_66 = 66,
  RUBYTOWN_67 = 67,
  RUBYTOWN_68 = 68,
  RUBYTOWN_69 = 69,
  RUBYTOWN_70 = 70,
  RUBYTOWN_71 = 71,
  RUBYTOWN_72 = 72,
  RUBYTOWN_73 = 73,
  RUBYTOWN_74 = 74,
  RUBYTOWN_75 = 75,
  RUBYTOWN_76 = 76,
  RUBYTOWN_77 = 77,
  RUBYTOWN_78 = 78,
  RUBYTOWN_79 = 79,
  RUBYTOWN_80 = 80,
  RUBYTOWN_81 = 81,
  RUBYTOWN_82 = 82,
  RUBYTOWN_83 = 83,
  RUBYTOWN_84 = 84,
  RUBYTOWN_85 = 85,
  RUBYTOWN_86 = 86,
  RUBYTOWN_87 = 87,
  RUBYTOWN_88 = 88,
  RUBYTOWN_89 = 89,
  RUBYTOWN_90 = 90,
  RUBYTOWN_91 = 91,
  RUBYTOWN_92 = 92,
  RUBYTOWN_93 = 93,
  RUBYTOWN_94 = 94,
  RUBYTOWN_95 = 95,
  RUBYTOWN_96 = 96,
  RUBYTOWN_97 = 97,
  RUBYTOWN_98 = 98,
  RUBYTOWN_99 = 99,
  RUBYTOWN_100 = 100,
  RUBYTOWN_101 = 101,
  RUBYTOWN_102 = 102,
  RUBYTOWN_103 = 103,
  RUBYTOWN_104 = 104,
  RUBYTOWN_105 = 105,
  RUBYTOWN_106 = 106,
  RUBYTOWN_107 = 107,
  RUBYTOWN_108 = 108,
  RUBYTOWN_109 = 109,
  RUBYTOWN_110 = 110,
  RUBYTOWN_111 = 111,
  RUBYTOWN_112 = 112,
  RUBYTOWN_113 = 113,
  RUBYTOWN_114 = 114,
  RUBYTOWN_115 = 115,
  RUBYTOWN_116 = 116,
  RUBYTOWN_117 = 117,
  RUBYTOWN_118 = 118,
  RUBYTOWN_119 = 119,
  RUBYTOWN_120 = 120,
  RUBYTOWN_121 = 121,
  RUBYTOWN_122 = 122,
  RUBYTOWN_123 = 123,
  RUBYTOWN_124 = 124,
  RUBYTOWN_125 = 125,
  RUBYTOWN_126 = 126,
  RUBYTOWN_127 = 127,
  RUBYTOWN_128 = 128,
  RUBYTOWN_129 = 129,
  RUBYTOWN_130 = 130,
  RUBYTOWN_131 = 131,
  RUBYTOWN_132 = 132,
  RUBYTOWN_133 = 133,
  RUBYTOWN_134 = 134,
  RUBYTOWN_135 = 135,
  RUBYTOWN_136 = 136,
  RUBYTOWN_137 = 137,
  RUBYTOWN_138 = 138,
  RUBYTOWN_139 = 139,
  RUBYTOWN_140 = 140,
  RUBYTOWN_141 = 141,
  RUBYTOWN_142 = 142,
  RUBYTOWN_143 = 143,
  RUBYTOWN_144 = 144,
  RUBYTOWN_145 = 145,
  RUBYTOWN_146 = 146,
  RUBYTOWN_147 = 147,
  RUBYTOWN_148 = 148,
  RUBYTOWN_149 = 149,
  RUBYTOWN_150 = 150,
  RUBYTOWN_151 = 151,
  RUBYTOWN_152 = 152,
  RUBYTOWN_153 = 153,
  RUBYTOWN_154 = 154,
  RUBYTOWN_155 = 155,
  RUBYTOWN_156 = 156,
  RUBYTOWN_157 = 157,
  RUBYTOWN_158 = 158,
  RUBYTOWN_159 = 159,
  RUBYTOWN_160 = 160,
  RUBYTOWN_161 = 161,
  RUBYTOWN_162 = 162,
  RUBYTOWN_163 = 163,
  RUBYTOWN_164 = 164,
  RUBYTOWN_165 = 165,
  RUBYTOWN_166 = 166,
  RUBYTOWN_167 = 167,
  RUBYTOWN_168 = 168,
  RUBYTOWN_169 = 169,
  RUBYTOWN_170 = 170,
  RUBYTOWN_171 = 171,
  RUBYTOWN_172 = 172,
  RUBYTOWN_173 = 173,
  RUBYTOWN_174 = 174,
  RUBYTOWN_175 = 175,
  RUBYTOWN_176 = 176,
  RUBYTOWN_177 = 177,
  RUBYTOWN_178 = 178,
  RUBYTOWN_179 = 179,
  RUBYTOWN_180 = 180,
  RUBYTOWN_181 = 181,
  RUBYTOWN_182 = 182,
  RUBYTOWN_183 = 183,
  RUBYTOWN_184 = 184,
  RUBYTOWN_185 = 185,
  RUBYTOWN_186 = 186,
  RUBYTOWN_187 = 187,
  RUBYTOWN_188 = 188,
  RUBYTOWN_189 = 189,
  RUBYTOWN_190 = 190,
  RUBYTOWN_191 = 191,
  RUBYTOWN_192 = 192,
  RUBYTOWN_193 = 193,
  RUBYTOWN_194 = 194,
  RUBYTOWN_195 = 195,
  RUBYTOWN_196 = 196,
  RUBYTOWN_197 = 197,
  RUBYTOWN_198 = 198,
  RUBYTOWN_199 = 199,
  RUBYTOWN_200 = 200,
  RUBYTOWN_201 = 201,
  RUBYTOWN_202 = 202,
  RUBYTOWN_203 = 203,
  RUBYTOWN_204 = 204,
  RUBYTOWN_205 = 205,
  RUBYTOWN_206 = 206,
  RUBYTOWN_207 = 207,
  RUBYTOWN_208 = 208,
  RUBYTOWN_209 = 209,
  RUBYTOWN_210 = 210,
  RUBYTOWN_211 = 211,
  RUBYTOWN_212 = 212,
  RUBYTOWN_213 = 213,
  RUBYTOWN_214 = 214,
  RUBYTOWN_215 = 215,
  RUBYTOWN_216 = 216,
  RUBYTOWN_217 = 217,
  RUBYTOWN_218 = 218,
  RUBYTOWN_219 = 219,
  RUBYTOWN_220 = 220,
  RUBYTOWN_221 = 221,
  RUBYTOWN_222 = 222,
  RUBYTOWN_223 = 223,
  RUBYTOWN_224 = 224,
  RUBYTOWN_225 = 225,
  RUBYTOWN_226 = 226,
  RUBYTOWN_227 = 227,
  RUBYTOWN_228 = 228,
  RUBYTOWN_229 = 229,
  RUBYTOWN_230 = 230,
  RUBYTOWN_231 = 231,
  RUBYTOWN_232 = 232,
  RUBYTOWN_233 = 233,
  RUBYTOWN_234 = 234,
  RUBYTOWN_235 = 235,
  RUBYTOWN_236 = 236,
  RUBYTOWN_237 = 237,
  RUBYTOWN_238 = 238,
  RUBYTOWN_239 = 239,
  RUBYTOWN_240 = 240,
  RUBYTOWN_241 = 241,
  RUBYTOWN_242 = 242,
  RUBYTOWN_243 = 243,
  RUBYTOWN_244 = 244,
  RUBYTOWN_245 = 245,
  RUBYTOWN_246 = 246,
  RUBYTOWN_247 = 247,
  RUBYTOWN_248 = 248,
  RUBYTOWN_249 = 249,
  RUBYTOWN_250 = 250,
  RUBYTOWN_251 = 251,
  RUBYTOWN_252 = 252,
  RUBYTOWN_253 = 253,
  RUBYTOWN_254 = 254,
  RUBYTOWN_255 = 255,
}