import TileProvider from '@/base/tile/internal/TileProvider';
import {ProviderType} from '@/base/tile/providers/helpers/ProviderType';

export default class BridgeTileProvider<T> extends TileProvider<BridgeTile> {
  public provideBaseDirectory(): string {
    return "@/base/tile/assets/bridge"
  }

  public provideTileProviderType(): ProviderType {
    return ProviderType.BRIDGE;
  }
}
export enum BridgeTile {
  BRIDGE_TILE_0 = 80,
  BRIDGE_TILE_1 = 1,
  BRIDGE_TILE_2 = 2,
  BRIDGE_TILE_3 = 3,
  BRIDGE_TILE_4 = 4,
  BRIDGE_TILE_5 = 5,
  BRIDGE_TILE_6 = 6,
  BRIDGE_TILE_7 = 7,
  BRIDGE_TILE_8 = 8,
  BRIDGE_TILE_9 = 9,
  BRIDGE_TILE_10 = 10,
  BRIDGE_TILE_11 = 11,
  BRIDGE_TILE_12 = 12,
  BRIDGE_TILE_13 = 13,
  BRIDGE_TILE_14 = 14,
  BRIDGE_TILE_15 = 15,
  BRIDGE_TILE_16 = 16,
  BRIDGE_TILE_17 = 17,
  BRIDGE_TILE_18 = 18,
  BRIDGE_TILE_19 = 19,
  BRIDGE_TILE_20 = 20,
  BRIDGE_TILE_21 = 21,
  BRIDGE_TILE_22 = 22,
  BRIDGE_TILE_23 = 23,
  BRIDGE_TILE_24 = 24,
  BRIDGE_TILE_25 = 25,
  BRIDGE_TILE_26 = 26,
  BRIDGE_TILE_27 = 27,
  BRIDGE_TILE_28 = 28,
  BRIDGE_TILE_29 = 29,
  BRIDGE_TILE_30 = 30,
  BRIDGE_TILE_31 = 31,
  BRIDGE_TILE_32 = 32,
  BRIDGE_TILE_33 = 33,
  BRIDGE_TILE_34 = 34,
  BRIDGE_TILE_35 = 35,
  BRIDGE_TILE_36 = 36,
  BRIDGE_TILE_37 = 37,
  BRIDGE_TILE_38 = 38,
  BRIDGE_TILE_39 = 39,
  BRIDGE_TILE_40 = 40,
  BRIDGE_TILE_41 = 41,
  BRIDGE_TILE_42 = 42,
  BRIDGE_TILE_43 = 43,
  BRIDGE_TILE_44 = 44,
  BRIDGE_TILE_45 = 45,
  BRIDGE_TILE_46 = 46,
  BRIDGE_TILE_47 = 47,
  BRIDGE_TILE_48 = 48,
  BRIDGE_TILE_49 = 49,
  BRIDGE_TILE_50 = 50,
  BRIDGE_TILE_51 = 51,
  BRIDGE_TILE_52 = 52,
  BRIDGE_TILE_53 = 53,
  BRIDGE_TILE_54 = 54,
  BRIDGE_TILE_55 = 55,
  BRIDGE_TILE_56 = 56,
  BRIDGE_TILE_57 = 57,
  BRIDGE_TILE_58 = 58,
  BRIDGE_TILE_59 = 59,
  BRIDGE_TILE_60 = 60,
  BRIDGE_TILE_61 = 61,
  BRIDGE_TILE_62 = 62,
  BRIDGE_TILE_63 = 63,
  BRIDGE_TILE_64 = 64,
  BRIDGE_TILE_65 = 65,
  BRIDGE_TILE_66 = 66,
  BRIDGE_TILE_67 = 67,
  BRIDGE_TILE_68 = 68,
  BRIDGE_TILE_69 = 69,
  BRIDGE_TILE_70 = 70,
  BRIDGE_TILE_71 = 71,
  BRIDGE_TILE_72 = 72,
  BRIDGE_TILE_73 = 73,
  BRIDGE_TILE_74 = 74,
  BRIDGE_TILE_75 = 75,
  BRIDGE_TILE_76 = 76,
  BRIDGE_TILE_77 = 77,
  BRIDGE_TILE_78 = 78,
  BRIDGE_TILE_79 = 79,
}