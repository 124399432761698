import TileProvider from '@/base/tile/internal/TileProvider';
import {ProviderType} from '@/base/tile/providers/helpers/ProviderType';

export default class NatureSupportTileProvider<T> extends TileProvider<NatureSupportTile> {
  public provideBaseDirectory(): string {
    return "@/base/tile/assets/nature_support";
  }

  public provideTileProviderType(): ProviderType {
    return ProviderType.NATURE_SUPPORT;
  }
}

export enum NatureSupportTile {
  WATER_STILL = 0,
  WATER_CURRENTS_VARIANT_1 = 1,
  WATER_CURRENTS_VARIANT_2 = 28,
  WATER_CURRENTS_VARIANT_3 = 29,
  GRASS_VARIANT_1_FLIPPED = 2,
  VINE_ON_PATCHY_GRASS_VARIANT_1 = 3,
  VINE_ON_PATCHY_GRASS_VARIANT_2 = 4,
  GRASS_VARIANT_2_FLIPPED = 5,
  GRASS_VARIANT_3_FLIPPED = 6,
  GRASS_VARIANT_4_FLIPPED = 7,
  GRASS_VARIANT_5_FLIPPED = 8,
  GRASS_VARIANT_6_FLIPPED = 9,
  GRASS_VARIANT_7_FLIPPED = 10,
  GRASS_VARIANT_8_FLIPPED = 11,
  GRASS_VARIANT_9_FLIPPED = 12,
  VINE_ON_PATCHY_GRASS_VARIANT_1_FLIPPED = 13,
  VINE_ON_PATCHY_GRASS_VARIANT_2_FLIPPED = 14,
  VINE_ON_PATCH_GRASS_VARIANT_3 = 15,
  VINE_ON_PATCH_GRASS_VARIANT_3_FLIPPED = 16,
  SHRUB_DARK_ON_TIDY_GRASS = 17,
  SHRUB_ON_TIDY_GRASS = 18,
  TREE_ON_PATCHY_GRASS = 19,
  TREE_ON_PATCHY_GRASS_FLIPPED = 20,
  TREE_ON_GRASS_FLIPPED = 21,
  SNOWY_PLAIN_GRASS = 22,
  SNOWY_PATCHY_GRASS = 23,
  SNOWY_SHRUB_ON_SNOW_VARIANT_1_FLIPPED = 24,
  SNOWY_SHRUB_ON_SNOW_VARIANT_2_FLIPPED = 25,
  SNOWY_PATCHY_GRASS_VARIANT_2 = 26,
  SNOWY_PATCHY_GRASS_VARIANT_3 = 27,
  SAND_PLAIN = 30,
}